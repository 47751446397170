import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, filter, map, take, mergeMap, takeUntil } from 'rxjs/operators';
import { IInitialData } from 'wz-types/page-data.d';
import { Globals } from '~shared/classes';
import { LoginDialogComponent } from '~shared/dialogs';
import { CommonService, SeoService } from '~shared/services';
import { CategoryCarouselComponent } from '../../components/category-carousel/category-carousel.component';
import { ColorCarouselComponent } from '../../components/color-carousel/color-carousel.component';
import { ProductCarouselComponent } from '../../components/product-carousel/product-carousel.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '~shared/services';
import { IHelpCenterRequest } from 'wz-types';
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'wz-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit , AfterViewInit {
  bannerMessage = Globals.siteSettings.bannerMessage;
  primaryCta: { height: string; width: string; };
  secondaryCta: { height: string; width: string; };
  public homePageData: IInitialData = Globals.initialLoadData;
  public enablePrevForCategory = false;
  public enableNextForCategory = true;
  public enablePrevForColour = false;
  public enableNextForColour = true;
  public enablePrevForNewListings = false;
  public enableNextForNewListings = true;
  public enablePrevForCustomMadeListings = false;
  public enableNextForCustomMadeListings = true;
  public enablePrevForPreLovedListings = false;
  public enableNextForPreLovedListings = true;
  public enablePrevForFeaturedListings = false;
  public enableNextForFeaturedListings = true;
  public enablePrevForNewArrivals = false;
  public enableNextForNewArrivals = true;
  @ViewChild(CategoryCarouselComponent) private _categoryCarouselComponent: CategoryCarouselComponent;
  @ViewChild(ColorCarouselComponent) private _colourCarouselComponent: ColorCarouselComponent;
  @ViewChild('productCarouselNewListings') private _productCarouselNewListingsComponent: ProductCarouselComponent;
  @ViewChild('productCarouselCustomMadeListings') private _productCarouselCustomMadeListingsComponent: ProductCarouselComponent;
  @ViewChild('productCarouselPreLovedListings') private _productCarouselPreLovedListingsComponent: ProductCarouselComponent;
  @ViewChild('productCarouselFeaturedListings') private _productCarouselFeaturedListingsComponent: ProductCarouselComponent;
  @ViewChild('productCarouselNewArrivals') private _productCarouselNewArrivalsComponent: ProductCarouselComponent;
    
  config = Globals.defaultSwiperConfig;
  helpForm: FormGroup;

  constructor(
    private seoSrv: SeoService,
    private alertSrv: AlertService,
    private commonService: CommonService,
    private router: Router,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private http: HttpClient
    ) { 
  }

  ngAfterViewInit() {
    for (let i = 0; i < this.route.snapshot.url.length; i++) {
      if (this.route.snapshot.url[i].path === 'register') {
        this.openAuthentication(true);
        break;
      }
    }
  }

  ngOnInit() {
    $(document).ready(function() {            
          $('.headercarousel').slick({
             infinite: true,
             autoplay: true,
             autoplaySpeed: 5500,
            // dots: true,
             adaptiveHeight: true,
             prevArrow: false,
             nextArrow: false 
          });
          $('.headercarouselmobile').slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5500,
          //  dots: true,
            adaptiveHeight: true,
            prevArrow: false,
            nextArrow: false
      });
    });
    this.seoSrv.generateTags({ title: 'The Wedding Marketplace for New, Used & Custom Wedding Items' });
    this.onWindowResize(window.innerWidth);

    this.helpForm = this.formBuilder.group({
      email: [undefined, [Validators.required, Globals.emailValidator()]],
    });
  }

  joinMailingList() {
    const userId = Globals.user && Globals.user.id ? Globals.user.id : null;
    this.alertSrv.confirm('Ready to join the mailing list?', '').pipe(
      filter(r => !!r),
      mergeMap(() => this.http.post(`${Globals.environment.apiUrl}users/mailing-list`, { ...this.helpForm.getRawValue(), userId })),
      map((r: IHelpCenterRequest) => {
        this.alertSrv.alert('Success', `Thank you for joining our mailing list!`);
        this.router.navigateByUrl('/');
      }),
      take(1)
    ).subscribe(() => {
      this.helpForm.get('email').setValue(null);
    });
  }

  onWindowResize(innerWidth: number) {
    const isUnder = Globals.isWidthLessThan(innerWidth);
    const lgHeight = '225px;';
    this.primaryCta = { width: '65%', height: lgHeight };
    this.secondaryCta =  { width: '30%', height: lgHeight };

    if (isUnder.md) {
      this.primaryCta = { width: '90%', height: '100px' };
      this.secondaryCta =  { width: '30%', height: lgHeight };
    }
  }

  /**
  * Determines how the content block text/image should be structured on the page (i.e.  `row wrap` for text left & image right, or `column-reverse` image top & text bottom).
  * @param index
  */
  getLayoutRuleForContentTile(index: number): string {
    const isUnder = Globals.isWidthLessThan(innerWidth);
      if (isUnder.md || index > 0) {
      // Every content tile on a medium -> smaller sized device, or non-hero image will appear the same
      return 'column-reverse';
    } else if (index === 0) {
      // Hero text/image for left/right layout
      return 'row wrap';
    }
  }

  clickedCta(i: number) {
    if (i === 0) {
      this.router.navigateByUrl('new-arrivals');
    } else {
      const isLoggedIn = !!Globals.user && !!Globals.user.email;
      const onSignedIn = () => this.router.navigateByUrl('become-a-seller');
      if (isLoggedIn) {
        onSignedIn();
      } else {
        LoginDialogComponent.open(true, this.matDialog).pipe(
          delay(1500),
          filter(() => Globals.user.isLoggedIn()),
          map(() => onSignedIn()),
          takeUntil(Globals.destroy$)
        ).subscribe();
      }
    }
  }

  public slideCategoryCarouselBackward() {
    this.enableNextForCategory = true;
    this._categoryCarouselComponent.directiveRef.prevSlide(3000, true);
    this._categoryCarouselComponent.directiveRef.prevSlide(3000, true);
    if (this._categoryCarouselComponent.directiveRef.getIndex() > 0) {
      this.enablePrevForCategory = true;
    } else {
      this.enablePrevForCategory = false;
      this.enableNextForCategory = true;
    }
  }

  public slideCategoryCarouselForward() {    
    const index = this._categoryCarouselComponent.directiveRef.getIndex();
    this._categoryCarouselComponent.directiveRef.nextSlide(3000, true);
    this._categoryCarouselComponent.directiveRef.nextSlide(3000, true);  

    if (index === this._categoryCarouselComponent.directiveRef.getIndex()) {
       this.enableNextForCategory = false;     
    }  
    if (this._categoryCarouselComponent.directiveRef.getIndex() > 0) {
      this.enablePrevForCategory = true;
    } else {
      this.enablePrevForCategory = false;
      this.enableNextForCategory = true;
    }    
  }

  public slideColourCarouselBackward() {
    this.enableNextForColour = true;
    this._colourCarouselComponent.directiveRef.prevSlide(3000, true);
    this._colourCarouselComponent.directiveRef.prevSlide(3000, true);
    if (this._colourCarouselComponent.directiveRef.getIndex() > 0) {
      this.enablePrevForColour = true;
    } else {
      this.enablePrevForColour = false;
      this.enableNextForColour = true;
    }
  }

  public slideColourCarouselForward() {
    const index = this._colourCarouselComponent.directiveRef.getIndex();
    this._colourCarouselComponent.directiveRef.nextSlide(3000, true);
    this._colourCarouselComponent.directiveRef.nextSlide(3000, true);

    if (index === this._colourCarouselComponent.directiveRef.getIndex()) {
       this.enableNextForColour = false;     
    }  
    if (this._colourCarouselComponent.directiveRef.getIndex() > 0) {
      this.enablePrevForColour = true;
    } else {
      this.enablePrevForColour = false;
      this.enableNextForColour = true;
    }
  }

  public slideNewListingsBackward(): void {
    this.enableNextForNewListings = true;
    this._productCarouselNewListingsComponent.directiveRef.prevSlide();
    if (this._productCarouselNewListingsComponent.directiveRef.getIndex() > 0) {
      this.enablePrevForNewListings = true;
    } else {
      this.enablePrevForNewListings = false;
      this.enableNextForNewListings = true;
    }
  }

  public slideNewListingsForward(): void {
    const index = this._productCarouselNewListingsComponent.directiveRef.getIndex();
    this._productCarouselNewListingsComponent.directiveRef.nextSlide();
    if (index === this._productCarouselNewListingsComponent.directiveRef.getIndex()) {
      this.enableNextForNewListings = false;
    }  
    if (this._productCarouselNewListingsComponent.directiveRef.getIndex() > 0) { 
      this.enablePrevForNewListings = true;
    } else {     
      this.enablePrevForNewListings = false;
      this.enableNextForNewListings = true;
      if (index === this._productCarouselNewListingsComponent.directiveRef.getIndex()) {
        this.enableNextForNewListings = false;
      }
    }
  }

  public slideCustomMadeListingsBackward(): void {
    this.enableNextForCustomMadeListings = true;
    this._productCarouselCustomMadeListingsComponent.directiveRef.prevSlide();
    if (this._productCarouselCustomMadeListingsComponent.directiveRef.getIndex() > 0) {
      this.enablePrevForCustomMadeListings = true;
    } else {
      this.enablePrevForCustomMadeListings = false;
      this.enableNextForCustomMadeListings = true;
    }
  }

  public slideCustomMadeListingsForward(): void {
    const index = this._productCarouselCustomMadeListingsComponent.directiveRef.getIndex();
    this._productCarouselCustomMadeListingsComponent.directiveRef.nextSlide();
    if (index === this._productCarouselCustomMadeListingsComponent.directiveRef.getIndex()) {
      this.enableNextForCustomMadeListings = false;
    }  
    if (this._productCarouselCustomMadeListingsComponent.directiveRef.getIndex() > 0) { 
      this.enablePrevForCustomMadeListings = true;
    } else {     
      this.enablePrevForCustomMadeListings = false;
      this.enableNextForCustomMadeListings = true;
      if (index === this._productCarouselCustomMadeListingsComponent.directiveRef.getIndex()) {
        this.enableNextForCustomMadeListings = false;
      }
    }
  }

  public slidePreLovedListingsBackward(): void {
    this.enableNextForPreLovedListings = true;
    this._productCarouselPreLovedListingsComponent.directiveRef.prevSlide();
    if (this._productCarouselPreLovedListingsComponent.directiveRef.getIndex() > 0) {
      this.enablePrevForPreLovedListings = true;
    } else {
      this.enablePrevForPreLovedListings = false;
      this.enableNextForPreLovedListings = true;
    }
  }

  public slidePreLovedListingsForward(): void {
    const index = this._productCarouselPreLovedListingsComponent.directiveRef.getIndex();
    this._productCarouselPreLovedListingsComponent.directiveRef.nextSlide();
    if (index === this._productCarouselPreLovedListingsComponent.directiveRef.getIndex()) {
      this.enableNextForPreLovedListings = false;
    }  
    if (this._productCarouselPreLovedListingsComponent.directiveRef.getIndex() > 0) { 
      this.enablePrevForPreLovedListings = true;
    } else {     
      this.enablePrevForPreLovedListings = false;
      this.enableNextForPreLovedListings = true;
      if (index === this._productCarouselPreLovedListingsComponent.directiveRef.getIndex()) {
        this.enableNextForPreLovedListings = false;
      }
    }
  }

  public slideFeaturedListingsBackward(): void {
    this.enableNextForFeaturedListings = true;
    this._productCarouselFeaturedListingsComponent.directiveRef.prevSlide();
    if (this._productCarouselFeaturedListingsComponent.directiveRef.getIndex() > 0) {
      this.enablePrevForFeaturedListings = true;
    } else {
      this.enablePrevForFeaturedListings = false;
      this.enableNextForFeaturedListings = true;
    }
  }

  public slideFeaturedListingsForward(): void {
    const index = this._productCarouselFeaturedListingsComponent.directiveRef.getIndex();
    this._productCarouselFeaturedListingsComponent.directiveRef.nextSlide();
    if (index === this._productCarouselFeaturedListingsComponent.directiveRef.getIndex()) {
      this.enableNextForFeaturedListings = false;
    }  
    if (this._productCarouselFeaturedListingsComponent.directiveRef.getIndex() > 0) { 
      this.enablePrevForFeaturedListings = true;
    } else {     
      this.enablePrevForFeaturedListings = false;
      this.enableNextForFeaturedListings = true;
      if (index === this._productCarouselFeaturedListingsComponent.directiveRef.getIndex()) {
        this.enableNextForFeaturedListings = false;
      }
    }
  }

  public slideNewArrivalsBackward(): void {
    this.enableNextForNewArrivals = true;
    this._productCarouselNewArrivalsComponent.directiveRef.prevSlide();
    if (this._productCarouselNewArrivalsComponent.directiveRef.getIndex() > 0) {
      this.enablePrevForNewArrivals = true;
    } else {
      this.enablePrevForNewArrivals = false;
      this.enableNextForNewArrivals = true;
    }
  }

  public slideNewArrivalsForward(): void {
    const index = this._productCarouselNewArrivalsComponent.directiveRef.getIndex();
    this._productCarouselNewArrivalsComponent.directiveRef.nextSlide();
    if (index === this._productCarouselNewArrivalsComponent.directiveRef.getIndex()) {
      this.enableNextForNewArrivals = false;
    }  
    if (this._productCarouselNewArrivalsComponent.directiveRef.getIndex() > 0) { 
      this.enablePrevForNewArrivals = true;
    } else {     
      this.enablePrevForNewArrivals = false;
      this.enableNextForNewArrivals = true;
      if (index === this._productCarouselNewArrivalsComponent.directiveRef.getIndex()) {
        this.enableNextForNewArrivals = false;
      }
    }
  }

  becomeASeller() {
    this.router.navigateByUrl('/become-a-seller');
  }

  openAuthentication(isRegistration?: boolean) {
    this.commonService.openAuthentication(isRegistration);
  }

}

import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Globals } from '~shared/classes';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';

let splitHref = location.href.split('/');
const indexOfSeo = splitHref.indexOf('seo');
if (indexOfSeo > -1) {
  splitHref = splitHref.filter((v, i) => i !== indexOfSeo);
  location.href = splitHref.join('/');
}

Sentry.init({
  dsn: 'https://d3a464fcd4b341bcaa8ba7cdba315e4e@o569278.ingest.sentry.io/5714864',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['https://dev.wedzee.com', 'https://www.wedzee.com'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  sampleRate: location.href.indexOf('localhost') === -1 ? 1.0 : 0,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
  environment: Globals.environment.production ? 'production' : 'development'
});

if (Globals.environment.production && location.href.indexOf('localhost') === -1) {
  enableProdMode();
  if (window) {
    window.console.log = function() {};
  }
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));

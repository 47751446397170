import { Component, EventEmitter, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ICategory } from 'wz-types/categories';
import { IWeddingColor } from 'wz-types/home-page';
import { IProductListPageQueryParams } from 'wz-types/listings';
import { Globals } from '~shared/classes';
import { ListingsStore } from '~shared/stores';

@Component({
  selector: 'wz-product-list-filter',
  templateUrl: './product-list-filter.component.html',
  styleUrls: ['./product-list-filter.component.scss']
})
export class ProductListFilterComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Input() isBottomSheet = false;
  @Input() initialParams: IProductListPageQueryParams;
  selectedCategory: ICategory;
  categoryBreadcrumbList: ICategory[] = [];
  expandCategory = false;
  expandConditions = false;
  expandColor = false;
  expandFabric = false;
  expandKeyword = false;
  expandedPanel: string;
  expandPrice = false;
  expandSilhouette = false;
  expandSize = false;
  isFilteringDresses = false;
  bottomSheetParams: any = {};
  selectedColor$: Observable<IWeddingColor>;
  selectedPriceOption: any = '0_0';
  selectedConditionOption: any = 'Any';
  searchKeyword: any;
  selectedSizeCategory = 'Regular';
  mainMenuCategories = Globals.categories.filter(c => c.isInMainMenu);

  expanded = {
    silhouette: false,
    fabric: false,
    size: false
  };

  conditionOptions = Globals.siteSettings.listingConditionOptions;
  dressOptions = {
    sizeCategories: Object.keys(Globals.siteSettings.dressSizesLookup),
    sizesLookup: Globals.siteSettings.dressSizesLookup,
    silhouette: Globals.siteSettings.dressSilhouetteOptions || [],
    fabric: Globals.siteSettings.dressFabricOptions
  };

  weddingColors$: Observable<IWeddingColor[]>;

  destroy$: Subject<void> = new Subject();

  priceForm: FormGroup;

  priceRadioButtons = [
    { label: 'Any price', value: { priceHigh: 0, priceLow: 0 }, checked: true, val: '0_0' },
    { label: 'Under $25', value: { priceHigh: 25, priceLow: 0 }, checked: false, val: '0_25' },
    { label: '$25 to $50', value: { priceHigh: 50.01, priceLow: 25 }, checked: false, val: '25_50' },
    { label: '$50 to $100', value: { priceHigh: 100.01, priceLow: 50 }, checked: false, val: '50_100' },
    { label: 'Over $100', value: { priceHigh: Infinity, priceLow: 100 }, checked: false, val: '100_Infinity' },
    { label: 'Custom', value: 'custom', checked: false, val: 'custom' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private bottomSheet: MatBottomSheet,
    private zone: NgZone,
    private router: Router,
    private listingStore: ListingsStore,
    @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any
  ) { 
    if (!this.conditionOptions.includes('Any')) {
      this.conditionOptions.unshift('Any');
    }
  }

  public static presentBottomSheet(initialParams: IProductListPageQueryParams, bottomSheet: MatBottomSheet) {
    const sheetRef = bottomSheet.open(ProductListFilterComponent, { data: { ...initialParams, isBottomSheet: true } });
    return sheetRef.afterDismissed();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const previousProductListPageQueryParams = this.listingStore.getProductListPageQuery();
      if (previousProductListPageQueryParams !== undefined) {
        this.setKeywordAndExpandPanel(previousProductListPageQueryParams.searchText, previousProductListPageQueryParams.expandedPanel === 'Keyword');
        this.setPriceOptionAndExpandPanel(previousProductListPageQueryParams.priceLow, previousProductListPageQueryParams.priceHigh, 
          previousProductListPageQueryParams.expandedPanel === 'Price');
        this.setConditionOptionAndExpandPanel(previousProductListPageQueryParams.condition, previousProductListPageQueryParams.expandedPanel === 'Condition');
        this.setColorAndExpandPanel(previousProductListPageQueryParams.colorId, previousProductListPageQueryParams.expandedPanel === 'Color');
        this.expandCategory = previousProductListPageQueryParams.expandedPanel === 'Category';
        this.expandSilhouette = previousProductListPageQueryParams.expandedPanel === 'Silhouette';
        this.expandFabric = previousProductListPageQueryParams.expandedPanel === 'Fabric';
        this.expandSize = previousProductListPageQueryParams.expandedPanel === 'Size';
      }
    }, 100);
  }

  public expansionPanelOpened(panelName: string): void {
    this.expandedPanel = panelName;
  }

  ngOnInit() { // todo: reset old values
    this.weddingColors$ = of(Globals.colors);
    this.priceForm = this.formBuilder.group({
      priceHigh: [undefined],
      priceLow: [undefined]
    });

    if (this.bottomSheetData.isBottomSheet) {
      this.isBottomSheet = true;

      this.priceForm.valueChanges.subscribe((value) => {
        if ((value.priceLow || value.priceLow === 0) && (value.priceHigh || value.priceHigh === 0)) {
          this.updateQueryParams({ priceLow: value.priceLow, priceHigh: value.priceHigh});
        }
      });

      delete this.bottomSheetData.isBottomSheet;
      this.initialParams = this.bottomSheetData;
      this.bottomSheetParams = this.bottomSheetData;
      if (this.bottomSheetData.colorId) {
        this.selectedColor$ = of(Globals.colors.find(c => c.id === this.bottomSheetData.colorId));
      }
      if ((this.bottomSheetData.priceHigh || this.bottomSheetData.priceHigh === '0') && (this.bottomSheetData.priceLow || this.bottomSheetData.priceLow === '0')) {
        let checkVal = false;
        this.priceRadioButtons.forEach((value) => {
            if (value.val === this.bottomSheetData.priceLow + '_' + this.bottomSheetData.priceHigh) {
              checkVal = true;
            }    
        });
        if (checkVal) {
          this.selectedPriceOption = this.bottomSheetData.priceLow + '_' + this.bottomSheetData.priceHigh;
        } else {
          this.selectedPriceOption = 'custom';
          this.priceForm.get('priceHigh').setValue(this.bottomSheetData.priceHigh);
          this.priceForm.get('priceLow').setValue(this.bottomSheetData.priceLow);
        }
      } else {
        this.selectedPriceOption = '0_0';
      }
      if (this.bottomSheetData.condition) {
        this.selectedConditionOption = this.bottomSheetData.condition;
      } else {
        this.selectedConditionOption = 'Any';
      }

      const { priceLow, priceHigh } = this.bottomSheetData;
      if (!!priceLow || !!priceHigh) {
        for (const r of this.priceRadioButtons) {
          if (typeof r.value !== 'string' && (priceLow || 0) === r.value.priceLow && priceHigh === r.value.priceHigh) {
            this.priceRadioButtons[0].checked = false;
            r.checked = true;
          }
        }
        if (this.priceRadioButtons[0].checked) {
          this.priceRadioButtons[0].checked = false;
          this.priceRadioButtons[this.priceRadioButtons.length - 1].checked = true;
          this.priceForm.get('priceHigh').setValue(priceHigh);
          this.priceForm.get('priceLow').setValue(priceLow);
        }
      }

      this.selectedCategory = Globals.categoriesLookup[this.initialParams.categoryId];
      this.updateIsFilteringDresses();
      this.updated.pipe(
        map((newParams: any) => {
          this.bottomSheetParams = { ...this.bottomSheetParams, ...newParams };
          this.initialParams = this.bottomSheetParams;
        }),
        takeUntil(this.destroy$)
      ).subscribe();
    }
  }

  updateIsFilteringDresses() {
    this.isFilteringDresses = (this.initialParams.searchText && this.initialParams.searchText.toLowerCase().includes('dress')) ||
        (!!this.selectedCategory && this.selectedCategory.name.toLowerCase().indexOf('wedding dress') > -1);
  }

  ngOnChanges() {
    if (this.initialParams.categoryId) {
      this.categoryIdSelected(this.initialParams.categoryId);
    }
    if (this.initialParams.colorId) {
      this.selectedColor$ = of(Globals.colors.find(c => c.id === this.initialParams.colorId));
    }
    if (this.bottomSheetData.isBottomSheet) {
      this.isBottomSheet = true;
      delete this.bottomSheetData.isBottomSheet;
      this.initialParams = this.bottomSheetData;
    }
    if (this.initialParams.searchText) {
      this.updateIsFilteringDresses();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  categoryIdSelected(id: string) {
    this.selectedCategory = Globals.categoriesLookup[id];
    this.updateIsFilteringDresses();
    this.rebuildCategoryBreadcrumbList();
  }

  rebuildCategoryBreadcrumbList() {
    this.categoryBreadcrumbList = [];
    let currentCategory = this.selectedCategory;

    // traverse up the category tree until we reach the top level category
    while (currentCategory.parentCategoryId) {
      const parentCategory = Globals.categoriesLookup[currentCategory.parentCategoryId];
      this.categoryBreadcrumbList.push(Globals.categoriesLookup[currentCategory.parentCategoryId]);
      currentCategory = parentCategory;
    }

  }

  getSubcategories() {
    if (this.selectedCategory.isInMainMenu) {
      return Globals.categories.filter(c => c.parentCategoryId === this.selectedCategory.id);
    }
    return Globals.categories.filter(c => c.parentCategoryId === this.selectedCategory.parentCategoryId);
  }

  selectCategory(category) {
    this.categoryIdSelected(category.id);
    if (this.isBottomSheet === false) {
      this.router.navigateByUrl(`category/${category.id}`);
    }
  }

  selectCustomPriceRadioBtn() {
    this.selectedPriceOption = 'custom';
  }

  updateQueryParams(params: any) {
    if (params.colorId === 'none') {
      params.colorId = undefined;
      this.selectedColor$ = undefined;
    } else if (params.colorId) {
      this.selectedColor$ = of(Globals.colors.find(c => c.id === params.colorId));
    }
    if (params.condition === 'Any') {
      params.condition = null;
    }
    if (params.priceHigh && params.priceLow) {
      params.priceHigh = Number(params.priceHigh);
      params.priceLow = Number(params.priceLow);
    }
    params.expandedPanel = this.expandedPanel;
    this.updated.emit(params);
  }

  resetFilter() {
    // Param Object
    const params = { colorId: 'none', priceHigh: 0, priceLow: 0, condition: null, searchText: null, resetToCategoryPath: null, dressSize: [] };

    // Reset Colors
    params.colorId = undefined;
    this.selectedColor$ = undefined;

    // Reset Price Radio Button Selection
    this.priceRadioButtons.forEach((value) => {
      value.checked = false;
    });

    this.priceForm.get('priceHigh').setValue(undefined);
    this.priceForm.get('priceLow').setValue(undefined);
    this.selectedPriceOption = '0_0';
    this.priceRadioButtons[0].checked = true;

    // Reset condition Filter
    this.selectedConditionOption = 'Any';

    // Reset Keywords
    this.searchKeyword = null;

    this.selectedSizeCategory = 'Regular';

    if (this.categoryBreadcrumbList !== null && this.categoryBreadcrumbList.length > 0) {
      const categoryNameForUrl = this.categoryBreadcrumbList[0].name.trim().toLowerCase().replace(/ /g, '-').replace(/&/g, '');
      params.resetToCategoryPath = `${this.categoryBreadcrumbList[0].id}/${categoryNameForUrl}`;
    }

    // Emit Event
    this.updated.emit(params);
  }

  toggleDressParams(property: string, value: string) {
    let array = this.initialParams[property] || [];
    const index = array.indexOf(value);
    if (index === -1) array.push(value);
    else array = array.filter((v, i) => i !== index);
    this.updateQueryParams({ [property]: array });
  }

  bottomSheetDone() {
    this.zone.run(() => {
      // tslint:disable-next-line:no-unused-expression
      if (this.isBottomSheet) {
        if (this.selectedCategory !== undefined) {
          this.initialParams.categoryId = this.selectedCategory.id;
          this.bottomSheetParams.categoryId = this.selectedCategory.id;
        }
        this.bottomSheet._openedBottomSheetRef.dismiss(this.bottomSheetParams);
      }
    });
  }

  bottomSheetCancel() {
    return this.isBottomSheet ? this.bottomSheet.dismiss() : undefined;
  }

  public setConditionOptionAndExpandPanel(conditionOption: string, expandPanel: boolean): void {
    if (conditionOption !== undefined && conditionOption !== null) {
      this.selectedConditionOption = conditionOption;
    }
    this.expandConditions = expandPanel;
  }

  public setColorAndExpandPanel(colorId: string, expandPanel: boolean): void {
    if (colorId !== undefined && colorId !== null) {
      this.selectedColor$ = of(Globals.colors.find(c => c.id === colorId));
    }
    this.expandColor = expandPanel;
  }

  private setKeywordAndExpandPanel(keyword: string, expandPanel: boolean): void {
    if (keyword !== undefined && keyword !== null) {
      this.searchKeyword = keyword;
    }
    this.expandKeyword = expandPanel;
  }

  private setPriceOptionAndExpandPanel(priceLow: number, priceHigh: number, expandPanel: boolean): void {
    if (priceLow !== undefined && priceHigh !== undefined && priceLow !== null && priceHigh !== null) {
      let priceOption = `${priceLow}_${priceHigh}`;

      let priceOptionExists = false;
      for (let i = 0; i < this.priceRadioButtons.length; i++) {
        if (this.priceRadioButtons[i].val === priceOption) {
          priceOptionExists = true;
          break;
        }
      }

      if (priceOptionExists === false) {
        priceOption = 'custom';
        this.priceForm.get('priceLow').setValue(priceLow);
        this.priceForm.get('priceHigh').setValue(priceHigh);
      }

      this.selectedPriceOption = priceOption;
    }

    this.expandPrice = expandPanel;
  }

}

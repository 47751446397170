import { ViewportScroller } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthHandlerPageComponent } from '~shared/pages/auth-handler/auth-handler.component';
import { ForgotPasswordComponent } from '~shared/pages/forgot-password/forgot-password.component';

import { ImpersonateHandlerComponent } from './components/impersonate-handler/impersonate-handler.component';
import { ConsumerSellOnWedzeePageComponent } from './components/sell-on-wedzee-page/sell-on-wedzee-page.component';
import { AffiliateProgramComponent } from './components/affiliate-program/affiliate-program.component';
import { CategoriesPageComponent } from './pages/categories-page/categories-page.component';
import { FeaturedListingsPageComponent } from './pages/featured-listings-page/featured-listings-page.component';


import { HomePageComponent } from './pages/home-page/home-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { ProductListPageComponent } from './pages/product-list-page/product-list-page.component';
import { TermsOfServicePageComponent } from './pages/terms-of-service-page/terms-of-service-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
  },
  {
    path: 'register',
    component: HomePageComponent,
  },
  {
    path: 'auth-handler',
    component: AuthHandlerPageComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/my-profile-page/my-profile-page.module').then(m => m.MyProfilePageModule)
  },
  {
    path: 'dispute/:orderId/:billToAddressZip/:listingId',
    loadChildren: () => import('./pages/file-dispute-page/file-dispute-page.module').then(m => m.FileDisputePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about-page/about-page.module').then(m => m.AboutPageModule)
  },
  {
    path: 'terms-of-service',
    component: TermsOfServicePageComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPageComponent
  },
  {
    path: 'products/:filterType/:filterParam',
    component: ProductListPageComponent
  },
  {
    path: 'category/:categoryId',
    component: ProductListPageComponent
  },
  {
    path: 'category/:categoryId/:urlName',
    component: ProductListPageComponent
  },
  {
    path: 'color/:colorId',
    component: ProductListPageComponent
  },
  {
    path: 'new-arrivals',
    component: ProductListPageComponent
  },
  {
    path: 'featured-listings',
    component: FeaturedListingsPageComponent
  },
  {
    path: 'categories',
    component: CategoriesPageComponent    
  },
  {
    path: 'wedding-colors',
    loadChildren: () => import('./pages/wedding-colors/wedding-colors-page.module').then(m => m.WeddingColsPageModule)
  },
  {
    path: 'product/:listingId',
    loadChildren: () => import('./pages/product-detail-page/product-detail-page.module').then(m => m.ProductDetsPageModule)
  },
  {
    path: 'product/:listingId/:productUrlName',
    loadChildren: () => import('./pages/product-detail-page/product-detail-page.module').then(m => m.ProductDetsPageModule)
  },
  {
    path: 'shopping-cart',
    loadChildren: () => import('./pages/shopping-cart-page/shopping-cart-page.module').then(m => m.ShoppingCartPageModule)
  },
  {
    path: 'shipping',
    loadChildren: () => import('./pages/enter-shipping-page/enter-shipping-page.module').then(m => m.EnterShippingPageModule)
  },
  // To Be Removed
  // {
  //   path: 'billing',
  //   loadChildren: () => import('./pages/enter-billing-page/enter-billing-page.module').then(m => m.EnterBillingPageModule)
  // },
  {
    path: 'review-order',
    loadChildren: () => import('./pages/review-order-page/review-order-page.module').then(m => m.ReviewOrderPageModule)
  },
  {
    path: 'my-orders',
    loadChildren: () => import('./pages/my-orders-page/my-orders-page.module').then(m => m.MyOrdersPageModule)
  },
  {
    path: 'order-placed/:orderId',
    loadChildren: () => import('./pages/order-placed-page/order-placed-page.module').then(m => m.OrderPlacedPageModule)
  },
  {
    path: 'order-details/:orderId/:billingZip',
    loadChildren: () => import('./pages/order-details-page/order-details-page.module').then(m => m.OrderDetailsPageModule)
  },
  {
    path: 'order-lookup',
    loadChildren: () => import('./pages/order-lookup-page/order-lookup-page.module').then(m => m.OrderLookupPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq-page/faq-page.module').then(m => m.FaqPageModule)
  },
  {
    path: 'dispute/order-lookup',
    loadChildren: () => import('./pages/order-lookup-page/order-lookup-page.module').then(m => m.OrderLookupPageModule)
  },
  {
    path: 'search/:searchText',
    component: ProductListPageComponent
  },
  {
    path: 'how-it-works',
    loadChildren: () => import('./pages/info-page/info-page.module').then(m => m.InfoPageModule)
  },
  {
    path: 'sell-on-wedzee',
    component: ConsumerSellOnWedzeePageComponent
  },
  {
    path: 'affiliate-program',
    component: AffiliateProgramComponent
  },
  {
    path: 'sell-on-wedzee/register',
    component: ConsumerSellOnWedzeePageComponent
  },
  {
    path: 'become-a-seller',
    loadChildren: () => import('./pages/become-seller-page/become-seller-page.module').then(m => m.BecomeSellerPageModule)
  },
  {
    path: 'seller-agreement',
    loadChildren: () => import('./pages/seller-agreement-page/seller-agreement-page.module').then(m => m.SellerAgreementPageModule)
  },
  {
    path: 'seller-registered',
    loadChildren: () => import('./pages/seller-account-registered/seller-account-registered.module').then(m => m.SellerAccountRegisteredModule)
  },
  {
    path: 'seller',
    loadChildren: () => import('./seller-dashboard/seller-dashboard.module').then(m => m.SellerDashboardModule)
  },
  {
    path: 'profile/:sellerName',
    loadChildren: () => import('./pages/seller-profile-page/seller-profile-page.module').then(m => m.SellerProfilePageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/help-center-page/help-center-page.module').then(m => m.HelpCenterPageModule)
  },
  {
    path: 'impersonate',
    component: ImpersonateHandlerComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        setTimeout(() => {viewportScroller.scrollToPosition(e.position); }, 0);
      } else if (e.anchor) {
        // anchor navigation
        setTimeout(() => {viewportScroller.scrollToAnchor(e.anchor); }, 0);
      } else {
        // forward navigation
        setTimeout(() => {viewportScroller.scrollToPosition([0, 0]); }, 0);
      }
    });
  }
}

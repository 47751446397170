<ng-container *ngIf="siteSettings as s" [ngSwitch]="!!s.isSiteDisabled">
  <ng-container *ngSwitchCase="false">
    <ng-container *ngIf="!!isInitializing || !!loadingMessage">
      <div class="app-loading">
        <div class="logo">
          <svg class="spinner" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </div>
        <div *ngIf="loadingMessage" class="message">
          {{loadingMessage}}
        </div>
      </div>
    </ng-container>

    <br/>
    <ng-container>
      <mat-progress-bar color="primary" *ngIf="isLoading$ | async" mode="indeterminate" style="z-index: 99999999; position: fixed; top: 0; left: 0; right: 0;"></mat-progress-bar>
      <wz-navbar></wz-navbar>
         

      <div class="main-router-outlet-container" [ngClass]="{'main-router-outlet-container-iframe': isInIframe() == true}">
        <div class="money-back-container" ngStyle.gt-sm="height:51px; !important;" *ngIf="isInIframe() === false">
          <wz-money-back-banner></wz-money-back-banner>
        </div>

        <div class="page-container" [ngClass]="{'full-page-container': currentRoute == '/', 'page-container-iframe': isInIframe() == true}" wzScrollable>
          <router-outlet (activate)="!!$event.wzOnNavigate ? $event.wzOnNavigate() : undefined"></router-outlet>
        </div>
      </div>
      
      <wz-footer *ngSwitchCase="false"></wz-footer>
    </ng-container>
  </ng-container> 

  <ng-container *ngSwitchCase="true">
    <div class="app-loading">
      <div class="logo"></div>
      <div class="ql-editor" style="text-align: center; padding: 20px;" [innerHtml]="s.siteDisabledHtml"></div>
    </div>
  </ng-container>

</ng-container>
